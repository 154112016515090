<!--
 * @Descripttion: 组价收缩页面
 * @Author: liuxia
 * @Date: 2021-10-21 18:17:27
 * @LastEditors: liuxia
 * @LastEditTime: 2022-07-07 15:39:47
-->
<template>
  <div class="retract-price" ref="content" v-drag>
    <div
      class="invitation"
      v-if="invitationIsShow"
      @click.stop="openInvitation"
    >
      <icon-font class="icon" type="icon-zhaobiao"></icon-font>
      <span>招标</span>
    </div>
    <div class="tender" v-if="tenderIsShow" @click.stop="openTender">
      <icon-font class="icon" type="icon-toubiao"></icon-font>
      <span>投标</span>
    </div>
    <div
      v-if="
        (isUnitProject && (!isUnfold || !isTenderUnfold)) ||
        (!isUnitProject && !isRetractUnfold)
      "
      class="combined-price"
      @click="handleOptions"
      :style="
        combinedPriceStatus || isPriceAdjustmentOrValuation
          ? 'height: 86px;'
          : ''
      "
    >
      <img
        v-if="combinedPriceStatus || isPriceAdjustmentOrValuation"
        src="https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/loadingyun.gif"
      />
      <span>{{
        combinedPriceStatus || isPriceAdjustmentOrValuation
          ? '正在组价'
          : '一键组价'
      }}</span>
    </div>
    <div class="export-project" v-if="isExportMinimality" @click="openExport">
      <img
        src="https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/DYJSX.gif"
        alt
      />
      <span>{{ getExportProcess }}%</span>
    </div>
    <!--    <div class="unFold" @click.prevent="handleOptions">-->
    <!--      <icon-font class="icon" type="icon-fangda-bai"></icon-font>-->
    <!--    </div>-->
    <!--    <span :style="combinedPriceStatus || isPriceAdjustmentOrValuation ? '' : 'height: 127px;'">{{-->
    <!--      combinedPriceStatus || isPriceAdjustmentOrValuation-->
    <!--        ? '组价中'-->
    <!--        : '一键组价'-->
    <!--    }}</span>-->
    <!--    <img-->
    <!--      v-if="combinedPriceStatus || isPriceAdjustmentOrValuation"-->
    <!--      src="https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJ/yun.gif"-->
    <!--      alt-->
    <!--    />-->
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

let innerHeight, height, top, maxHeight, transY;

function stringifyTranslate(str) {
  if (!str) {
    return [0, 0];
  }
  return str
    .match(/\((.+)\)/)[1]
    .replace(/px/g, '')
    .split(/,\s*/)
    .map((s) => +s);
}

function calcClientRect(el) {
  innerHeight = window.innerHeight;
  height = el.getBoundingClientRect().height;
  top = el.getBoundingClientRect().top;
  maxHeight = innerHeight - height;
  transY = stringifyTranslate(el.style.transform)[1];
  if (top < 0) {
    el.style.transform = `translate(0, ${transY - top}px)`;
  }
}

export default Vue.extend({
  name: 'retractPrice',
  data() {
    return {
      contentWidth: 0,
      contentHeight: 0,
    };
  },
  computed: {
    ...mapGetters([
      'isRetractUnfold',
      'combinedPriceStatus',
      'isPriceAdjustmentOrValuation',
      'isUnitProject',
      'isSingleProject',
      'isUnfold',
      'isTenderUnfold',
      'projectBidSequenceNbr',
      'isExportMinimality',
      'getExportProcess',
      'readOnly',
    ]),
    invitationIsShow() {
      return this.isUnitProject && this.isUnfold && !this.isTenderUnfold;
    },
    tenderIsShow() {
      return this.isUnitProject && this.isTenderUnfold && !this.isUnfold;
    },
  },
  mounted() {
    this.contentWidth = this.$refs.content.clientWidth;
    this.contentHeight = this.$refs.content.clientHeight;
  },
  directives: {
    drag: {
      // 指令的定义
      inserted: function (el) {
        const minHeight = 0;
        let y, distance;
        let startTime, endTime;
        calcClientRect(el);
        function moveHandler(e) {
          console.log('moveHandler');
          endTime = new Date().getTime();
          if (endTime - startTime > 100) {
            el.dataset.isDrag = 1;
          }
          const transleteY = e.clientY - y + distance;
          const clientHeight = transleteY + top;
          el.style.transform = `translate(0, ${
            clientHeight < minHeight
              ? -top + transY
              : clientHeight > maxHeight
              ? maxHeight - top + transY
              : transleteY + transY
          }px)`;
        }
        function upHandler() {
          console.log('upHandler');
          document.removeEventListener('mousemove', moveHandler);
          document.removeEventListener('mouseup', upHandler);
        }
        function downHandler(e) {
          startTime = new Date().getTime();
          console.log('downHandler');
          el.dataset.isDrag = 0;
          distance = el.getBoundingClientRect().top - top;
          y = e.clientY;
          document.addEventListener('mousemove', moveHandler);
          document.addEventListener('mouseup', upHandler);
        }
        function resizeHandler(el) {
          calcClientRect(el);
        }
        el.addEventListener('mousedown', downHandler);
        window.addEventListener('resize', resizeHandler.bind(window, el));
      },
      componentUpdated(el) {
        calcClientRect(el);
      },
    },
  },
  methods: {
    /**
     * 展开收缩方法
     */
    handleOptions() {
      if (this.readOnly) {
        this.$store.commit('SET_LOGINVISIBLE', true);
        return;
      }
      const isDrag =
        document.getElementsByClassName('retract-price')[0].dataset.isDrag;
      if (isDrag === '1') {
        return;
      }
      if (this.isUnitProject && (!this.isTenderUnfold || !this.isUnfold)) {
        this.$store.commit('SET_ISUNFOLD', true);
        this.$store.commit('SET_ISTENDERUNFOLD', true);
        this.$store.commit('SET_ISRETRACTUNFOLD', true);
      } else {
        this.$store.commit('SET_ISRETRACTUNFOLD', !this.isRetractUnfold);
      }
      this.$emit('Unfold', 1);
    },
    openInvitation() {
      const isDrag =
        document.getElementsByClassName('retract-price')[0].dataset.isDrag;
      if (isDrag === '1') {
        return;
      }
      this.$store.commit('SET_ISUNFOLD', !this.isUnfold);
      if (!this.isUnfold) {
        this.$store.commit('SET_ISTENDERUNFOLD', true);
      }
    },
    openTender() {
      const isDrag =
        document.getElementsByClassName('retract-price')[0].dataset.isDrag;
      if (isDrag === '1') {
        return;
      }
      this.$store.commit('SET_ISTENDERUNFOLD', !this.isTenderUnfold);
      if (!this.isTenderUnfold) {
        this.$store.commit('SET_ISUNFOLD', true);
      }
    },
    openExport() {
      const isDrag =
        document.getElementsByClassName('retract-price')[0].dataset.isDrag;
      if (isDrag === '1') {
        return;
      }
      this.$store.commit('SET_IS_EXPORT_MINIMALITY', false);
    },
  },
});
</script>

<style lang="less" scoped>
.retract-price {
  position: fixed;
  bottom: 45%;
  right: 0;
  z-index: 3;
  //width: 40px;
  //height: 144px;
  //background: #48a3fc;
  opacity: 1;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  color: #ffffff;
  .invitation {
    width: 54px;
    height: 60px;
    text-align: center;
    background: #2b96ff;
    box-shadow: 0 3px 6px rgba(30, 144, 255, 0.51);
    opacity: 1;
    border-radius: 4px 0 0 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 21px;
      margin-bottom: 5px;
    }
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }
  .tender {
    width: 54px;
    height: 60px;
    text-align: center;
    background: #ffaa09;
    box-shadow: 0 3px 6px rgba(255, 170, 9, 0.5);
    opacity: 1;
    border-radius: 4px 0 0 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 21px;
      margin-bottom: 5px;
    }
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }
  .combined-price {
    width: 54px;
    height: 60px;
    text-align: center;
    background: #67c23a;
    box-shadow: 0 3px 6px rgba(103, 194, 58, 0.38);
    opacity: 1;
    border-radius: 4px 0 0 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    padding: 0 12px;
    img {
      margin-bottom: 5px;
    }
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }
  .unFold {
    width: 52px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    background: #1e90ff;
    box-shadow: 0 3px 4px rgba(27, 119, 209, 0.54);
    opacity: 1;
    border-radius: 26px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    .icon {
      font-size: 18px;
    }
  }
  .export-project {
    width: 54px;
    height: 80px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(82, 82, 82, 0.2);
    border-radius: 4px 0 0 4px;
    margin-top: 5px;
    span {
      display: block;
      font-size: 12px;
      color: #45aeff;
      padding: 0 12px;
      line-height: 14px;
    }
  }
  //span {
  //  writing-mode: vertical-lr;
  //  font-size: 14px;
  //  font-weight: 400;
  //  line-height: 36px;
  //  color: #ffffff;
  //  padding-top: 54px;
  //}
}
</style>
